






















































































import Vue from "vue";
import gql from "graphql-tag";
import Model from "./model";
import Headline from "@/components/Headline.vue";
import FormSection from "@/components/FormSection.vue";
import TextInput from "@/components/form/TextInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import UploadInput from "@/components/form/UploadInput.vue";
import SaveBar from "@/components/form/SaveBar.vue";

export default Vue.extend({
  apollo: {
    pdb_modells_by_pk: {
      // GraphQL Query
      query: gql`
        query pdb_modells_by_pk($id: bigint!) {
          pdb_modells_by_pk(id: $id) {
            id
            online
            special
            name
            image
            image_dir
            font_image_active
            font_image_active_dir
            font_image_inactive
            font_image_inactive_dir
          }
        }
      `,
      // Reactive variables
      variables() {
        return {
          id: this.$route.params.modelID,
        };
      },
      skip() {
        // Skip request if no model id is provided in creation mode
        return this.skipQuery;
      },
      update(data) {
        console.log(data);
        // The returned value will update
        // the vue property 'pingMessage'
        return data.pdb_modells_by_pk;
      },
      result({ data, loading }) {
        if (!loading) {
          console.log(data);
          this.pdb_modells = data.pdb_modells_by_pk;
        }
      },
    },
  },
  data() {
    return {
      skipQuery: true,
      pdb_modells: {},
    };
  },
  created() {
    // Get model if model id is provided
    if (this.$route.params.modelID) {
      this.skipQuery = false;
    }
  },
  methods: {
    store() {
      // clone current model object and remove __typename property which was added by apollo
      const objectToStore: Model = Object.assign({}, this.pdb_modells);
      delete objectToStore.__typename;
      console.log(objectToStore);

      if (!this.$route.params.modelID) {
        // Create new model
        objectToStore.image_dir = "uploads/modell/image";
        objectToStore.font_image_inactive_dir =
          "uploads/modell/font_image_inactive";
        objectToStore.font_image_active_dir =
          "uploads/modell/font_image_active";
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation($objects: [pdb_modells_insert_input!]!) {
                insert_pdb_modells(objects: $objects) {
                  affected_rows
                }
              }
            `,
            // Parameters
            variables: {
              objects: objectToStore,
            },
          })
          .then((data) => {
            // Result
            console.log(data);
            this.$router.push("/models");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      } else {
        // Update model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation(
                $set: pdb_modells_set_input
                $pk: pdb_modells_pk_columns_input!
              ) {
                update_pdb_modells_by_pk(_set: $set, pk_columns: $pk) {
                  id
                }
              }
            `,
            // Parameters
            variables: {
              set: objectToStore,
              pk: { id: objectToStore.id },
            },
          })
          .then((data) => {
            // Result
            console.log(data);

            this.$router.push("/models");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      }
    },
    cancel() {
      this.$router.push("/models");
    },
  },
  components: {
    Headline,
    FormSection,
    TextInput,
    CheckboxInput,
    UploadInput,
    SaveBar,
  },
});
