





























import Vue from "vue";
import PaginationLink from "@/components/table/PaginationLink.vue";

export class PaginationObject {
  offset = 0;
  limit = 0;
  constructor(offset?: number, limit?: number) {
    if (offset) {
      this.offset = offset;
    }
    if (limit) {
      this.limit = limit;
    }
  }
}

export default Vue.extend({
  props: {
    pages: {
      type: Number,
      required: true,
    },
    options: {
      type: PaginationObject,
    },
    // limit: {
    //   type: Number,
    // },
    // offset: {
    //   type: Number,
    // },
  },
  model: {
    prop: "options",
    event: "paginationChange",
  },
  data() {
    return {
      offset: 0,
      currentPage: 1,
      //   limit: 1,
      limitArray: [10, 25, 50, 100],
    };
  },
  methods: {
    next() {
      if (this.currentPage !== this.pages) {
        this.currentPage = this.currentPage + 1;
        const currentOptions = this.options;
        const newParams = new PaginationObject(
          currentOptions.offset + currentOptions.limit,
          currentOptions.limit
        );
        this.$emit("paginationChange", newParams);
      }
    },
    prev() {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
        const currentOptions = this.options;
        const newParams = new PaginationObject(
          currentOptions.offset - currentOptions.limit,
          currentOptions.limit
        );
        this.$emit("paginationChange", newParams);
      }
    },
    jumpTo(page: number) {
      if (page <= this.pages) {
        this.currentPage = page;
        const currentOptions = this.options;
        const newParams = new PaginationObject(
          (page - 1) * currentOptions.limit,
          currentOptions.limit
        );
        this.$emit("paginationChange", newParams);
      }
    },
    selectLimit(event: any) {
      //   this.limit = parseInt(event.target.value);
      const currentOptions = this.options;
      const newParams = new PaginationObject(
        currentOptions.limit,
        event.target.value
      );
      // const newParams: PaginationObject = {
      //   offset: this.offset,
      //   limit: this.limit,
      // };
      this.$emit("limit-change", parseInt(event.target.value));
      this.$emit("paginationChange", newParams);
    },
  },
  created() {
    // this.limit = 10;
    // const newParams: PaginationObject = {
    //   offset: this.offset,
    //   limit: this.limit,
    // };
    const currentOptions = this.options;
    const newParams = new PaginationObject(
      currentOptions.limit,
      this.limitArray[0]
    );

    this.$emit("limit-change", this.limitArray[0]);
    this.$emit("paginationChange", newParams);
  },
  components: {
    PaginationLink,
  },
});
